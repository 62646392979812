import React from 'react'
import { ContainerLog } from "../../layouts/ContainerLog/ContainerLog";
import '../../layouts/ContainerLog/ContainerLog.css'

export const LogIN = () => {
  return (
    <div className="LogINpage">
        <ContainerLog/>
    </div>
  )
}
