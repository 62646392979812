import React from 'react'
import { NavBarLateral } from '../../ui/NavBarLateral/NavBarLateral'

export const Left = () => {
  return (
    <div className='Left'>
        <NavBarLateral/>
    </div>
  )
}
