import React from 'react'
import { NavBarLateralE } from '../../ui/NavBarLateralE/NavBarLateralE'

export const LeftE = () => {
  return (
    <div className='Left'>
        <NavBarLateralE/>
    </div>
  )
}
