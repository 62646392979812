import React from 'react'

export const TextVideo = () => {
  return (
    <div className="containerText">
        <p className="text1">Se parte de este lugar y comparte con nosotros tu trabajo</p>
        <p className="text2">Deja conocer tu gran emprendimiento</p>
    </div>
  )
}
